import React from "react"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import CategoryItems from "../../components/CategoryItems"
import CollectionTemplate from "../../templates/CollectionTemplate"

const HommePage = ({ data }) => {
  return (
    <CollectionTemplate
      collectionTitle="HOMME"
      classExtra="hommecollectionpage"
    >
      <SEO
        title="Homme"
        description={`Design your own engagement ring, earrings, pendant, bracelet. Gold and ethical, mining free gemstones (moissanite, morganite, lab diamond, sapphire). Vintage, solitaire, art deco, cluster.`}
      />
      <div className="productnavbar">
        <Link className="linkspacer" to="/collection">
          all
        </Link>{" "}
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/rings">
          rings
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/decollete">
          decollete
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/earrings">
          earrings
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bespoke">
          bespoke
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bracelets">
          bracelets
        </Link>
      </div>
      <div className="SEOContainer">
        {/* <div className="PicContain">
          <div className="SEOBespoke" />
        </div> */}
        <div className="SEOText">
          We believe that every body is beautiful and the rules of design and
          fashion were meant to be broken. As an LGBTQI+ ally, we encourage self
          discovery and adorning yourself in any way that makes you feel
          aligned. HOMME is a traditionally masculine subset of our collection,
          but don’t let the label pigeonhole your flair. Want something
          completely unique? Contact us to begin your bespoke journey!
        </div>
      </div>
      <div id="scroll" className="storewrap">
        <CategoryItems category="homme" />
      </div>
    </CollectionTemplate>
  )
}

export default HommePage
